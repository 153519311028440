<template :key="$store.state.sucursal">
<div id="page-articulos" >
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Administrar Stock Artículos" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                <template slot="codigo_barras" slot-scope="props">
                  {{ props.row.codigo_barras.join(", ") }}
                </template>

                <template slot="categoria" slot-scope="props">
                  {{props.row.categoria[0]}}
                </template>

                <template slot="actions" slot-scope="props">
                <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>

                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="300px" >
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Stock de Artículo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
      <v-form ref="form" lazy-validation>
      <v-row>
      <v-col >
        <v-text-field label="Minimo" v-model="stock.minimo" :rules="[rules.required, validaCantidadEntera]"></v-text-field>
      </v-col>
      </v-row>
       <v-row>
       <v-col >
        <v-text-field label="Reorden" v-model="stock.reorden" :rules="[rules.required, validaCantidadEntera]"></v-text-field>
      </v-col>
      </v-row>
      <v-row>
      <v-col >
        <v-text-field label="Máximo" v-model="stock.maximo" :rules="[rules.required, validaCantidadEntera]"></v-text-field>
      </v-col>
      </v-row>
      </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import Vue from "vue";
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('s.inventarios.stock')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
    if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
        this.$router.go();            
    }
  },
  data() {
    return {
      sucursal_activa:"",
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      active_tab: "datos",
      menu1: false,
      fecha_parsed: "",
      marcas: [],
      productos: [],
      unidades: [],
      articulos: [],
      impuestos: [],
      categorias: [],
      tipos_precios: [],
      fab: [],
      name: "datagrid",
      fechaUsuario: "",
      columns: [
        "codigo_barras",
        "nombre",
        "categoria",
        "marca.nombre",
        "minimo",
        "maximo",
        "reorden",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: ["codigo_barras", "nombre", "categoria", "marca.nombre"],
        sortable: ["codigo_barras", "nombre", "categoria", "marca.nombre"],

        headings: {
          "codigo_barras": "Código de Barras",
          "categoria": "Categoría",
          "marca.nombre": "Marca/Modelo",
          "precio_compra": "Precio de Compra",
          estatus: "Estatus",
          minimo: "Minimo",
          maximo: "Máximo",
          reorden: "Reorden",
          actions: "Operaciones"
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario",
          disabled: true,
          href: ""
        },
        {
          text: "Stock",
          href: ""
        }
      ],

      modal: false,

      update: false,

      model: {
        _id: "",
        _rev: "",
        type:"articulos",
        codigo_barras: "",
        nombre: "",
        created_at: "",
        estatus: "Activo",
        descripcion: "",
        categoria: "",
        caducidad: "",
        tiene_impuestos: "",
        tiene_grupo: "",
        marca: "",
        precios_venta: [],
        impuestos: [],
        grupo_articulos: [],
        producto_servicio: "",
        unidad_medida: "",
        stock: {},
      },
      stock:{
        minimo:"",
        maximo:"",
        reorden:""
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },

    };
  },

  methods: {
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      let self = this;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"articulos"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          if (x == "codigo_barras") {
            where[x] = {
              "$elemMatch": {
                "$regex": "(?i)" + this.model_filters[x]
              }
            }
          } else if (x == "categoria") {
            where[x] = {
              "$elemMatch": {
                "$regex": "(?i)" + this.model_filters[x]
              }
            }
          } else {
            where[x] = {
              "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
            }
          }
          
        }
      });
     where["estatus"] = {
       "$eq": "Activo"
     }
      console.log(where);

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            this.registros.items.forEach(function(i){
                if(i.stock != undefined){
                  if (i.stock[self.sucursal_activa] != undefined)
                  {
                    i.minimo = i.stock[self.sucursal_activa].minimo;
                    i.maximo = i.stock[self.sucursal_activa].maximo;
                    i.reorden = i.stock[self.sucursal_activa].reorden;
                  }
                }
            });
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },


    openModal: function(type) {
      if (type === 1) {
        this.update = false;

        this.model.created_at = window.moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechaUsuario = window.moment(this.model.created_at).format("DD-MM-YYYY");
      } else {
        this.update = true;
      }

      this.modal = true;
      this.active_tab = "datos";
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    validaFloat: function(campo) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      let value = this.model[campo];
      if (!pattern.test(value)) {
        this.model[campo] = this.model[campo].substring(0, this.model[campo].length - 1);
        this.validaFloat(campo);
        //console.log("INVALIDO")
      }
      //else
      //console.log("VALIDO");

    },
    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },
    validaCantidadEntera: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad entera no válida"
      } else
        return true;

    },
    requiredObject: function(val) {
      if (val != null && val.nombre != null && val != "") {
        return true;
      } else
        return "Este campo es requerido";
    },
    requiredArray: function(val) {
      if (val != null && val.length > 0) {
        return true;
      } else
        return "Este campo es requerido";
    },
    validaFecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida.";
      else
        return true;
    },
    validaNombre: function(val) {
      if (val != null) {
        if (this.esCampoUnico("nombre", val) == false)
          return "El nombre " + val + " ya se encuentra registrado.";
      }
      return true;
    },
    validaCodigo: function(val) {
      if (val != null) {
        if (this.esCampoUnico("codigo_barras", val) == false)
          return "El código " + val + " ya se encuentra registrado.";
      }
      return true;
    },

    esCampoUnico: function(campo, valor) {
      var filter = [];
      if (this.update) {
        var filter = this.registros.items.find(e => e[campo].toString().trim() == valor.toString().trim() && e._id != this.model._id);
      } else {
        var filter = this.registros.items.find(e => e[campo].toString().trim() == valor.toString().trim());
      }
      if (filter) {
        return false;
      }
      return true;
    },

    concatenedProducto: function(val) {
      return val.clave + " - " + val.nombre;
    },




    getRegistro: function(id_registro) {
      this.stock.minimo = "";
      this.stock.maximo = "";
      this.stock.reorden = "";
      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
        .then(response => {
          this.model = response.data;
          if(this.model.stock == undefined ){
              this.model.stock = {};
          }else{
            if(this.model.stock[this.sucursal_activa] != undefined)
                  this.stock = this.model.stock[this.sucursal_activa];
          }
          this.fechaUsuario = window.moment(this.model.created_at).format("DD-MM-YYYY");
          this.openModal(2);
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },

    updateRegistro: function() {

      if (this.$refs.form.validate() ) {


       let data = this.model;
        data.stock[this.sucursal_activa] = this.stock;

        window.dialogLoader.show('Espere un momento por favor..');
        window.axios
          .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
          .then(response => {
            window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
              color: 'success'
            });
            this.registros.items = [];
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            this.modal = false;
            window.dialogLoader.hide();
          });
      }
    },


  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: black;
  font-size: small;
  background-color: #EEEEEE;
  padding: 0px;
}

.v-tabs .v-tabs-bar .v-tab.v-tab--active {
  color: white;
  font-size: small;
  background-color: #df7205;
  padding: 0px;
  font-weight: bold;

}

.v-tabs-slider-wrapper {
  left: 0 !important;
}

.table-bordered thead th,
.table-bordered thead td {
  font-size: x-small !important;
}
</style>
